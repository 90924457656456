
.opblock-summary {
    display: none;
}

.opblock-summary {
    display: none;
}

.responses-wrapper .opblock-section-header {
	display: none;
}

.curl-command {
	display: none;
}

.code-samples-wrapper{
	display: none;
}

.modal .modal-full-height {
	width: calc( (100% - 260px) *0.4 );
}

.modal-dialog {
	max-width: 40%;
}

.parameters thead {
 display: none; 
}

.parameter__in{
  display: none; 
}

.parameter__deprecated{
  display: none; 
}

.parameter__type {
 display: none; 
}

.try-out {
  display: none;
}

.renderedMarkdown {
  display: none;
}

.execute-wrapper {
  display: none;
}

.btn-group{
  display: none;
}

.responses-table {
  display: none;
}

.live-responses-table{
  display: block;
}

.responses-header{
  display: none;
}

.download-contents{
  display: none;
}

.copy-to-clipboard{
  display: none;
}

.responses-inner h4{
  display: none;
}

.request-url{
  display: none;
}

.opblock h4 {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: black;
  margin-bottom: 5px;
  margin-top:0;
}

.opblock h5 {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: black;
  margin-bottom: 5px;
  margin-top:0;
}

pre.microlight {
  background-color: rgb(51,51,51);
  color: white;
  margin: 10px;
  padding: 10px !important;
}

.parameters-container {
  padding-left: 10px

}

.response-col_status {
  display: none;
}

.response-col_description {
  width: 100%;
  display: inline-block;
}

.responses-table tbody {
  width: 100%;
  display: inline-block;
}

tr.response {
  width: 100%;
  display: inline-block;
}

.parameters-col_description {
  padding-left: 10px;
  width: 100%;
}

.opblock-section-header label {
  width: 100%;
}
.content-type-wrapper: {
  display: block;
  width: 100%;
}


.opblock input.invalid {
  color: #495057;
  background-color: #fff;
  border-color: #f02929;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255,0,0,0.25);
}

.opblock input.invalid:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f02929;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255,0,0,0.25);
}


.opblock input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.opblock input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.opblock textarea.invalid {
  color: #495057;
  background-color: #fff;
  border-color: #f02929;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255,0,0,0.25);
}

.opblock textarea.invalid:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f02929;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255,0,0,0.25);
}

.opblock textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.opblock textarea {
    width: 100%;
    height: auto;
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-clip: padding-box;
    color: #495057;
    line-height: 1.5;
    font-weight: 400;
    font-size: 1rem;
    display: block;
    background-color: #fff;
    min-height: 250px;
}

.opblock select.invalid {
  color: #495057;
  background-color: #fff;
  border-color: #f02929;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255,0,0,0.25);
}

.opblock select.invalid:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f02929;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255,0,0,0.25);
}


.opblock select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}


.opblock select {
    display: block !important;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.parameter__name {
  font-weight: bold;
}

.scrollbar-container label {
    text-transform: capitalize;
    color: black !important;
    padding-top: 6px;
    padding-bottom: 6px;
}

.api-content h1 {
  text-transform: capitalize;
}

div.api-operation {
    padding: 15px 0;
    padding-top: 91px;
    margin-top: -68px;
}

div.api-tag {
    padding: 15px 0;
    padding-top: 91px;
    margin-top: -68px;
}

#api-spec{
  border-bottom: 1px solid rgba(0,0,0,0.2);
  padding-top: 91px;
  margin-top: -76px;
}

.api-auth {
  width: 100%;
  display: block;
  content: '';
  border-bottom: 1px solid rgba(0,0,0,0.2);
  padding-top: 91px;
  margin-top: -68px;
}

.api-auth h1 {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 1.85714em;
  line-height: 1.6em;
  color: #142392;
}

.api-auth h2 {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 1.57143em;
  line-height: 1.6em;
  color: black;
}

.api-auth .title {
    padding: 0 40px;
}

.api-auth .block {
  display: flex;
}

.doc {
  width: calc(100% - 40%);
  padding: 0 40px;
  padding-top: 21px;
}

.token-example {
  width: calc(100% - 60%);
  padding: 0px 40px;
  background-color: rgb(38, 50, 56);

}

blockquote {
  margin: 10px 20px;
  padding: 10px 20px;
  border-left: solid 5px rgba(110, 160, 255, 0.5);
  border-radius: 8px;
}

.api-content h3 {
  padding: 1.2rem 0
}

@media screen and (max-width: 1200px) {
  .api-auth .block {
    display: block;
  }

  .doc {
    width: 100%;
  }

  .token-example {
    width: 100%;
    padding: 20px 40px;
  }

  .modal .modal-full-height {
    width: calc(100% - 260px);
  }

  .modal-dialog {
    max-width: calc(100% - 260px);
  }
}


@media screen and (max-width: 800px) {
  .modal .modal-full-height {
    width: 100%;
  }

  .modal-dialog {
    max-width: 100%;
  }
}

.sample-content-type{
  display: none;
}

/*.react-tabs__tab-panel--selected div:first-of-type div[class^=sc-]:first-of-type {
  display: none;
}*/


.code-block {
  background-color: rgb(17, 23, 26);
  padding: 0px 20px;
  opacity: 0.9;
}

.code-block pre {
  background-color: transparent !important;
}

.code-block code {
  font-family: Courier,monospace !important;
  font-size: 13px;
  color: white;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: block;
  font-family: Courier, monospace;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  overflow: auto;
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  text-align: left;
  text-rendering: optimizespeed;
  white-space: pre
}

span.token.string {
  color: rgb(153, 204, 153) !important;
}

span.property.token.string {
  color: white !important;
}

.code-buttons {
  text-align: right;
  padding: 10px 0px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
} 


.code-buttons:hover {
  opacity: 1;
} 



.code-buttons button {
  background-color: transparent;
  border: none;
  padding: 5px 10px;

  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
  text-align: center;
  text-rendering: optimizespeed;
  text-transform: none
}

.code-buttons button:hover {
  background: rgba(255,255,255,0.1);
}

.status-400.tab-error {
  color: rgb(212, 138, 28) !important;
}

.status-400 button {
  background-color: rgb(212, 138, 28, 0.07);
  color: rgb(212, 138, 28) !important;
}

.status-400 polygon {
  fill: rgb(212, 138, 28) !important;
}


.status-401.tab-error {
  color: rgb(212, 138, 28) !important;
}

.status-401 button {
  background-color: rgb(212, 138, 28, 0.07);
  color: rgb(212, 138, 28) !important;
}

.status-401 polygon {
  fill: rgb(212, 138, 28) !important;
}


.status-403.tab-error {
  color: rgb(212, 138, 28) !important;
}

.status-403 button {
  background-color: rgb(212, 138, 28, 0.07);
  color: rgb(212, 138, 28) !important;
}

.status-403 polygon {
  fill: rgb(212, 138, 28) !important;
}

.status-404.tab-error {
  color: rgb(212, 138, 28) !important;
}

.status-404 button {
  background-color: rgb(212, 138, 28, 0.07);
  color: rgb(212, 138, 28) !important;
}

.status-404 polygon {
  fill: rgb(212, 138, 28) !important;
}

.server-response h4 {
  display: block;
}


.server-response {
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  /*border-top-color: ;*/
  padding: 10px;
}

.server-response .status-error {
  padding: 10px;
  background-color: rgba(212,31,28,0.07);
  border-radius: 2px;
}

.server-response .status-ok {
  padding: 10px;
  background-color: rgba(29,129,39,0.07);
  border-radius: 2px;
}

.server-response .status-200 {
  background-color: rgba(29,129,39,0.07) !important;
}

.server-response .status-400 {
  background-color: rgb(212, 138, 28, 0.07) !important;
}

.server-response .status-401 {
  background-color: rgb(212, 138, 28, 0.07) !important;
}

.server-response .status-402 {
  background-color: rgb(212, 138, 28, 0.07) !important;
}

.server-response .status-403 {
  background-color: rgb(212, 138, 28, 0.07) !important;
}

.server-response .status-404 {
  background-color: rgb(212, 138, 28, 0.07) !important;
}

.server-response .status-500 {
  background-color: rgba(212,31,28,0.07) !important;
}



.server-response pre {
  padding: 10px !important;
  margin: 10px;
}

.server-response .status-code {
  padding: 10px;
  font-weight: bolder;
}


.scrollbar-container .separator {
  display: block;
  width: 85%;
  margin: 5px 20px;
  border-bottom: 1px solid #e1e1e1;  
}

.download-spec {
  margin-left: -6px;
  margin-top: 10px;
}


.custom-menu-element {
  list-style: none inside none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}

.custom-menu-element label {
  cursor: pointer;
  margin: 0;
  padding: 12.5px 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: Montserrat,sans-serif;
  font-size: 0.929em;
  text-transform: none;
  background-color: #ededed;
}