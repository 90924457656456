.view img {
  min-height: 100%; 
  width: 100%;  
  -o-object-fit: cover; 
  object-fit: cover; 
  overflow: hidden;

  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

#usage-info {
  padding-top: 20px;
}

#usage-info a {
  color: #142392;
}

.splash {
  min-height: 100vh;
  margin-top: -76px;
}

main {
  margin-top: 76px;
}

nav img {
  height: 50px;
}

.btn-elegant {
  background-color: #334256 !important;
}


.navbar-color {
  background-color: #334256 !important;
}

.try-it {
  float: right;
}

.hidden {
  display: none !important;
}

pre{
  color: white;
}


.elegant-color {
  background-color: #263238 !important;
}



/* Release notes markdown formatting */

.release-notes .header-block {
  background-color: rgb(237, 237, 237);
  padding: 10px 40px;
}

.header-block > h2 {
  margin-bottom: 0px;
}

/* GENERAL PAGE HEADERS */
.release-notes h1 {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.6em;
  color: #32329f;
}


.release-notes h2 {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.6em;
  color: black;
}

.release-notes h3 {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.6em;
  color: black;
}


.release-notes h4 {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6em;
  color: black;
}


.release-notes h5 {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6em;
  color: black;
}

.release-notes h6 {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6em;
  color: black;
}


/* MARKDOWN HEADERS */

.release-note h1 {
  font-family: Montserrat,sans-serif;
  font-weight: bold;
  font-size: 1.3em;
  line-height: 1.6em;
  color: rgb(57,111,183);
  padding: 30px 0px 15px 0px;
}


.release-note h2 {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6em;
  color: rgba(38, 50, 56, 0.5);
  text-transform: uppercase;
  border-bottom: solid 1px;
  border-color: rgba(38, 50, 56, 0.5);
  padding-top: 10px;
  margin-bottom: 10px;
}

.release-note h3 {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6em;
  color: rgba(38, 50, 56, 0.5);
  text-transform: uppercase;

}

.release-note h4 {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6em;
  color: rgba(38, 50, 56, 0.5);
  text-transform: uppercase;
  border-bottom: solid 1px;
  border-color: rgba(38, 50, 56, 0.5);
  padding-top: 20px;
  margin-bottom: 10px;
}

blockquote > h4:first-of-type {
  padding-top: 0px;
}

.release-note p {
  font-family: Roboto,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  color: #333333;
  text-align: left;
  text-rendering: optimizeSpeed !important;
  -webkit-text-size-adjust: 100%;
  margin: 0px;
}

.release-note h6 {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6em;
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  background: rgba(110, 160, 255, 0.7);
  border-radius: 5px;
  color: white;
}


/* MARKDOWN HEADERS++ */

.release-note ol > li > p {
  line-height: 15px;
}

.release-note ol > li > p > em {
  display: inline-block;
  width: 150px;
  font-family: Courier,monospace;
  font-size: 13px;
  line-height: 15px;
  font-style: normal;
  padding-top: 5px;
}

.release-note ol > li > em {
  display: inline-block;
  width: 150px;
  font-family: Courier,monospace;
  font-size: 13px;
  line-height: 15px;
  font-style: normal;
  padding-top: 5px;
}

.release-note ol > li > p > strong {
  display: inline-block;
  width: 150px;
  font-family: Courier,monospace;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  padding-top: 5px;
}


.release-note ol > li > strong {
  display: inline-block;
  width: 150px;
  font-family: Courier,monospace;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  padding-top: 5px;
}



/* block */

.release-note ol {
  padding-left: 0px;
}

.release-note > blockquote > ul {
  padding-left: 0px;
}

.release-note ol > li > ::marker {
    content: "";
    width: 0px;
}

.release-note ol > li::before {
  content: '';
  display: inline-block;
  width: 11px;
  height: 20px;
  background-image: url("../assets/img/marker.png");
  background-repeat: no-repeat;
  background-position: center; 
  margin-right: 10px;
}

.release-note blockquote {
  margin: 10px 20px;
  padding: 10px 20px;
  border-left: solid 5px rgba(110, 160, 255, 0.5);
  border-radius: 8px;

}

.release-note ol > li > blockquote {
  display: inline-block;
  width: calc(100% - 180px);
  padding: 0px;
  margin: 0px;
  margin-left: 20px;
  border: none;
}


.release-note blockquote > ul {
  padding-left: 15px;
}

.release-note > blockquote {
  margin: 0px;
  padding-left: 20px;
  border: none;
}


.release-note pre {
  padding: 10px;
  background-color: rgb(17, 23, 26);
  margin: 10px;

  font-size: 13px;
  box-sizing: border-box;
  font-family: Courier, monospace;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  overflow: auto;
  overflow-x: auto;
  overflow-y: auto;
  text-align: left;
  text-rendering: optimizespeed;
}

.release-note li {
  margin-top: 10px;
}


.release-note ol > li {
  display: flex;
  flex: 1 1;
  margin-top: 20px;
}


/* restart */


div.release-notes {
  display: flex;
  position: relative;
}



/* scrollbar layout */




.release-notes div.scrollbar-container  ul {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}


.release-notes div.scrollbar-container  li {
  list-style: inside none none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  box-sizing: border-box;
}


.release-notes div.scrollbar-container label {
  text-transform: capitalize;
  padding: 6px 20px;
  box-sizing: border-box;
  cursor: pointer;
  color: rgb(51, 51, 51);
  margin: 0px;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  font-family: Montserrat, sans-serif;
  font-size: 0.929em;
}

.release-notes div.scrollbar-container label span{
  display: inline-block;
  vertical-align: middle;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}



/* Release notes redoc style */

div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

svg.menu-opener {
  height: 1.5em;
  width: 1.5em;
}

.release-notes-content {
  width: calc(100% - 260px);
  padding: 10px 0px;
  margin-left: 260px;
}

.api-header {
  padding: 0px 40px;
}

.api-main-header {
  padding: 0px 40px;
}

.api-main-header h1 {
  font-size: 26px;
  font-weight: 600;
}

.version-header {
  padding: 20px 40px 0px 40px;
}

.release-note {
  padding: 0px 40px;
  padding-bottom: 20px;
  
}

.release-notes-content > div.release-note:last-child {
    height: calc( 100vh - 104px );
    border: none;
}

a.rnlink {
  cursor: pointer;
  margin-left: -20px;
  padding: 0;
  line-height: 1;
  width: 20px;
  display: inline-block;
  outline: 0;
}

a.rnlink:before {
  content: '';
  width: 15px;
  height: 15px;
  background-size: contain;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMCIgeT0iMCIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cGF0aCBmaWxsPSIjMDEwMTAxIiBkPSJNNDU5LjcgMjMzLjRsLTkwLjUgOTAuNWMtNTAgNTAtMTMxIDUwLTE4MSAwIC03LjktNy44LTE0LTE2LjctMTkuNC0yNS44bDQyLjEtNDIuMWMyLTIgNC41LTMuMiA2LjgtNC41IDIuOSA5LjkgOCAxOS4zIDE1LjggMjcuMiAyNSAyNSA2NS42IDI0LjkgOTAuNSAwbDkwLjUtOTAuNWMyNS0yNSAyNS02NS42IDAtOTAuNSAtMjQuOS0yNS02NS41LTI1LTkwLjUgMGwtMzIuMiAzMi4yYy0yNi4xLTEwLjItNTQuMi0xMi45LTgxLjYtOC45bDY4LjYtNjguNmM1MC01MCAxMzEtNTAgMTgxIDBDNTA5LjYgMTAyLjMgNTA5LjYgMTgzLjQgNDU5LjcgMjMzLjR6TTIyMC4zIDM4Mi4ybC0zMi4yIDMyLjJjLTI1IDI0LjktNjUuNiAyNC45LTkwLjUgMCAtMjUtMjUtMjUtNjUuNiAwLTkwLjVsOTAuNS05MC41YzI1LTI1IDY1LjUtMjUgOTAuNSAwIDcuOCA3LjggMTIuOSAxNy4yIDE1LjggMjcuMSAyLjQtMS40IDQuOC0yLjUgNi44LTQuNWw0Mi4xLTQyYy01LjQtOS4yLTExLjYtMTgtMTkuNC0yNS44IC01MC01MC0xMzEtNTAtMTgxIDBsLTkwLjUgOTAuNWMtNTAgNTAtNTAgMTMxIDAgMTgxIDUwIDUwIDEzMSA1MCAxODEgMGw2OC42LTY4LjZDMjc0LjYgMzk1LjEgMjQ2LjQgMzkyLjMgMjIwLjMgMzgyLjJ6Ii8+PC9zdmc+Cg==');
  opacity: 0.5;
  visibility: hidden;
  display: inline-block;
  vertical-align: middle;
}
h1:hover > a.rnlink::before, h2:hover > a.rnlink::before, a.rnlink:hover::before {
  visibility: visible;
}

/* Side Menu colors */


/* Redoc */
.menu-content {
  background-color: rgb(237, 237, 237) !important;
}

.menu-content .subactive > label {
  background-color: rgb(113, 187, 217) !important;
}

.menu-content label:hover {
  background-color: rgb(113, 187, 217) !important;
}

.menu-content li.subactive {
    background-color: rgb(250, 250, 250);
}

.menu-content .subactive label.active {
  background-color: rgba(110, 160, 255, 0.1) ;
}

.menu-content li.active > label{
  background-color: rgba(110, 160, 255, 0.1) !important;
}

.menu-content ul.active li:first-of-type > label {
  background-color: rgba(110, 160, 255, 0.1);
}

.menu-content .subactive > ul label:hover {
   background-color: rgba(110, 160, 255, 0.2) !important;
}

.menu-content {
  top: 76px !important;
  height: calc(-76px + 100vh) !important;
}


/* release-notes */

/*.release-notes div.menu {
  background-color: rgb(237, 237, 237) !important; 
}*/

.release-notes div.menu .highlighted.depth1 > label {
  background-color: rgb(113, 187, 217) !important;
}

.release-notes div.menu li.depth1 > label:hover {
   background-color: rgb(113, 187, 217) !important;
}


.release-notes div.menu li.depth2 > label {
  padding-left: 30px; 
}

.release-notes div.menu ul.active li.depth2:first-of-type > label {
  background-color: rgba(110, 160, 255, 0.4);
}

.release-notes div.menu li.highlighted.depth2 > label {
  background-color: rgba(110, 160, 255, 0.4) !important;
}



.release-notes div.menu li.depth2 > label:hover {
   background-color: rgba(110, 160, 255, 0.5) !important;
}



.release-notes div.menu li.depth3 > label{
  padding-left: 40px; 
}


.release-notes div.menu  ul.active li.depth3:first-of-type > label {
  background-color: rgba(110, 160, 255, 0.1);
}

.release-notes div.menu li.highlighted.depth3 > label {
   background-color: rgba(110, 160, 255, 0.1) !important;
}

.release-notes div.menu li.depth3 > label:hover {
   background-color: rgba(110, 160, 255, 0.2) !important;
}



/* Side menu opener */

.sidemenu-toggle {
  outline: none;
  user-select: none;
  background-color: #f2f2f2;
  color: rgb(20, 35, 146);
  display: none;
  cursor: pointer;
  position: fixed;
  right: 10px;
  z-index: 100;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  bottom: 80px;
  width: 50px;
  height: 50px;
  padding: 0 16px;

}

.menu-toggle {
  right: 10px !important;
  bottom: 80px !important;
  width: 50px !important;
  height: 50px !important;
  padding: 0 16px !important;
}


.release-notes div.menu {
/*  width: 260px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  backface-visibility: hidden;
  position: fixed;
  top: 0px;
*/
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  color: rgb(51, 51, 51);
  text-align: left;
  text-rendering: optimizespeed !important;

 /* overflow: hidden !important;
  overflow-anchor: none;
  touch-action: auto;
  position: relative;
  box-sizing: border-box;*/
}

.release-notes .side-menu {
  width: 260px;
  background-color: rgb(237, 237, 237);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  backface-visibility: hidden;
  height: 100vh;
  position: fixed;
  top: 170;
}

/* responsive block */

@media print {
  .release-notes .side-menu {
    display: none;
  }

  .release-notes .sidemenu-toggle {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  
  h3.title {
    display: none;
  }

  .release-note ol > li::before {
    display: none;
  }
  
  .release-note ol > li {
    display: block;
  }

  .release-note ol > li > blockquote {
    display: block;
    width: 100%;
    margin-left: 0px;
  }

  .release-note ol > li > p > strong {
    margin-bottom: 10px;
  }

  .release-note ol > li > strong {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 800px) {

/*  .fixed-top {
    position: relative;
  }

  main {
    margin-top: 0px;
  }*/

  .release-notes div.menu {
    background: transparent !important;
  }

  .release-notes .side-menu {
    display: none;
  }

  .release-notes-content {
    width: 100%;
    margin-left: 0px;
  }

  .sidemenu-toggle{
    display: flex;
  }

  .release-notes .side-menu {
    display: none;
  }
  .release-notes .side-menu.opened {
    position: fixed;
    z-index: 20;
    width: 100%;
    display: flex;
  }
}

@media (max-width: 767px) {
    a.pl-5.navbar-brand {
        padding-left: 10px !important;
    }
}